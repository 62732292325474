import enNZ, { parseISO } from "date-fns";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { i18n } from "../../i18n";

/**
 * Formats the given date based on the en-NZ locale and timezone into a human readable formatted date string.
 *
 * Uses format and toDate from 'date-fns-tz' internally
 *
 * @param date - ISO string representing UTC time
 * @param dateFormat - defaults to "dd/MM/yyyy"
 *
 * @returns A string representing the formatted date in the Organization timezone
 *
 * @remarks
 * This functions works based on a **fixed time zone**
 * This is done because events should always be displayed in the time zone where the events takes place
 */
export const formatDate = (date: string | Date, dateFormat = "dd/MM/yyyy") => {
  const timeZone = i18n.timezone;

  return (
    date &&
    format(
      utcToZonedTime(
        typeof date === "string" ? parseISO(date) : date,
        timeZone
      ),
      dateFormat,
      {
        locale: enNZ,
        timeZone,
      }
    )
  );
};

export const utcToLocal = (date: Date | string | number) => {
  return utcToZonedTime(date, i18n.timezone);
};

export const localToUtc = (date: Date | string | number) => {
  return zonedTimeToUtc(date, i18n.timezone);
};

/**
 * Formats the date if given a string
 *
 * @param date - String representing the date
 *
 * @returns String with format dateArray[1]-dateArray[0]-dateArray[2] 😒
 */
export const formatDateFromString = (date: string) => {
  const dateArray = date.split("-");
  const formattedDate = [dateArray[1], dateArray[0], dateArray[2]].join("-");

  return formattedDate;
};

export const formatBroadcastDate = (scheduledAt: string) =>
  format(new Date(scheduledAt), "dd MMM',' yyyy 'at' HH'.'mm a");
